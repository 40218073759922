import { createSlice } from '@reduxjs/toolkit'
import storageService from '../services/StorageService';

export type AppSettingsState = Readonly<{
  isOnboardingPassed: boolean,
  isLoading: boolean,
  isSplashLoading: boolean,
}>;

const initialState: AppSettingsState = {
  isOnboardingPassed: !!storageService.get(storageService.KEYS.ONBOARDING_PASSED),
  isLoading: false,
  isSplashLoading: false,
}

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    passOnboarding: (state) => {
      state.isOnboardingPassed = true;
    },
    enableLoading: (state) => {
      state.isLoading = true;
    },
    enableSplashLoading: (state) => {
      state.isSplashLoading = true;
    },
    disableLoading: (state) => {
      state.isLoading = false;
      state.isSplashLoading = false;
    },
  },
})

export const { passOnboarding, enableLoading, enableSplashLoading, disableLoading } = appSettingsSlice.actions

export default appSettingsSlice.reducer;
