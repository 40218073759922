import React, { InputHTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cs from 'classnames';
import { isValidPhoneNumber } from 'libphonenumber-js'
import './TextInput.css'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>{
  onChangePlain?: (value: string, isValid: boolean) => void,
  phone?: boolean,
  invalid?: boolean,
  customRef?: React.RefObject<HTMLInputElement>
}

const TextInput: React.FC<InputProps> = function ({
  children ,
  className,
  onChangePlain = () => {},
  onChange: _onChange = () => {},
  value,
  invalid,
  phone,
  customRef,
  ...props
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isValid, setIsValid] = useState(true);
  const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    _onChange(event);
    onChangePlain(event.target.value, isValid);
  }, [onChangePlain, _onChange, isValid]);
  const activeRef = useMemo(() => {
    return customRef || inputRef
  }, [customRef, inputRef]);
  useEffect(() => {
    let newIsValid = true;
    const stringifiedValue = `${value || ''}`;
    if (activeRef.current) {
      if (phone) {
        newIsValid = isValidPhoneNumber(stringifiedValue) || isValidPhoneNumber(`+${stringifiedValue}`)
      } else {
        newIsValid = activeRef.current?.validity.valid;
      }
    }
    onChangePlain(stringifiedValue, newIsValid);
    setIsValid(newIsValid);
  }, [activeRef, phone, value, onChangePlain]);

  return (
    <input ref={activeRef} className={cs('input', className, {
      'input--not-valid': invalid || (!isValid && !!value)
    })} onChange={onChange} value={value} {...props}>
      {children}
    </input>
  )
}

export default TextInput;
