import React, { ButtonHTMLAttributes, useCallback } from 'react';
import cs from 'classnames';
import './InlineButton.css'
import { useHistory } from 'react-router-dom';

export interface InlineButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  to?: string,
}

const InlineButton: React.FC<InlineButtonProps> = function ({
  children ,
  className,
  onClick,
  to,
  ...props
}) {
  const history = useHistory();
  const defaultOnClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(() => {
    if (to) {
      history.push(to);
    }
  }, [to, history]);

  return (
    <button onClick={to ? defaultOnClick : onClick} className={cs('inline-button', className)} {...props}>
      {children}
    </button>
  )
}

export default InlineButton;
