import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import cs from 'classnames';
import './Link.css'

const CustomLink: React.FC<LinkProps> = function ({
  children ,
  className = '',
  ...props
}) {
  return (
    <Link className={cs('link', className)} {...props}>
      {children}
    </Link>
  )
}

export default CustomLink;
