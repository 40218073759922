import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IUser } from '../services/UserService';
import ConfigService from '../services/ConfigService';
import storageService from '../services/StorageService';
import { getPhoneOrEmail } from '../utils/phone';


export interface IUserStore extends IUser {
  isLoggedIn: boolean;
  isPasswordSet: boolean;
  isBusinessCreated: boolean;
}

interface IForgotPasswordResponse {
  codeId: number;
}

interface IForgotPasswordVerifyResponse {
  token: string;
}

interface IForgotPasswordVerifyData {
  login: string;
  codeId: number;
  code: string;
}

const businessApiConfig = ConfigService.get('businessApi');
export const userApi = createApi({
  reducerPath: 'userApi',
  tagTypes: ['User'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${businessApiConfig.baseUrl}/v1/users`,
    prepareHeaders: (headers, { getState }) => {
      const token = storageService.get(storageService.KEYS.TOKEN);
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getCurrentUser: builder.query<IUserStore, void>({
      query: () => '/current',
      transformResponse(user: IUser) {
        const isLoggedIn = !!user;
        const isBusinessCreated = user && !!user.businessId;
        return {
          ...user,
          isLoggedIn,
          isBusinessCreated,
        };
      },
      providesTags: ['User'],
    }),
    setPassword: builder.mutation<IUser, string>({
      query: (password: string) => ({
        url: '/set-password',
        method: 'POST',
        body: { password },
      }),
      invalidatesTags: ['User'],
    }),
    forgotPassword: builder.mutation<IForgotPasswordResponse, string>({
      query: (login: string) => {
        const { email, phone } = getPhoneOrEmail(login);

        return {
          url: '/forgot-password',
          method: 'POST',
          body: { email, phone },
        };
      },
    }),
    forgotPasswordVerify: builder.mutation<IForgotPasswordVerifyResponse, IForgotPasswordVerifyData>({
      query: (body: IForgotPasswordVerifyData) => {
        const { email, phone } = getPhoneOrEmail(body.login)

        return {
          url: '/forgot-password/verify',
          method: 'POST',
          body: {
            email,
            phone,
            codeId: body.codeId,
            code: body.code,
          },
        };
      },
      invalidatesTags: ['User'],
    }),
  }),
})

export const { useGetCurrentUserQuery, useSetPasswordMutation, useForgotPasswordMutation, useForgotPasswordVerifyMutation } = userApi
