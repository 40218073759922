import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  BUSINESS_CREATION,
  LOGIN,
  ONBOARDING,
  SIGN_IN,
  SIGN_UP,
  FORGOT_PASSWORD,
  VERIFICATION_IS_SENT,
  WELCOME
} from './constants/routes';
import Onboarding from './pages/Onboarding';
import Login from './pages/Login';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import Welcome from './pages/Welcome';
import BusinessCreation from './pages/BusinessCreation';
import VerificationIsSent from './pages/VerificationIsSent';
import { RootState } from './store/store';
import { useGetCurrentUserQuery } from './store/userApiSlice';
import { STEPS } from './pages/SignUp/SignUp';
import { disableLoading, enableLoading, enableSplashLoading } from './store/appSettingsSlice';
import { UserStatuses } from './services/UserService';



const AppRoutes: React.FC = function() {
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const token = useSelector((state: RootState) => state.currentUser.token);
  const { data: user, isFetching } = useGetCurrentUserQuery(undefined, {
    skip: !token,
  });
  const dispatch = useDispatch();
  const isOnboardingPassed = useSelector((state: RootState) => state.appSettings.isOnboardingPassed);
  const isLoggedIn = user && user.isLoggedIn;
  const isPasswordSet = user && user.isPasswordSet;
  const isBusinessCreated = user && user.isBusinessCreated;
  const isPasswordReset = user?.status === UserStatuses.PASSWORD_RESET_IN_PROGRESS;

  useEffect(() => {
    if (isFetching) {
      dispatch(isFirstRender ? enableSplashLoading() : enableLoading());
    } else {
      dispatch(disableLoading());
    }
  }, [isFetching, isFirstRender, dispatch]);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  return (
    <Switch>
      { !isOnboardingPassed && !isLoggedIn &&
        <>
          <Route path={ONBOARDING.path} component={Onboarding} />
          <Redirect to={ONBOARDING.path} />
        </>
      }
      {
        !isLoggedIn &&
          <>
            <Route path={LOGIN.path} component={Login}/>
            <Route path={SIGN_IN.path} component={SignIn}/>
            <Route path={SIGN_UP.path} component={SignUp} />
            <Route path={FORGOT_PASSWORD.path} component={ForgotPassword} />
            <Redirect to={LOGIN.path} />
          </>
      }
      { !isPasswordSet &&
        <>
          <Route path={SIGN_UP.path} render={() => (
            <SignUp
              initialStep={isPasswordReset ? STEPS.PASSWORD : undefined}
              initialEmail={user?.email || ''}
              initialPhone={user?.phone || ''}
              disableBack={isPasswordReset}
            />
          )} />
          <Redirect to={SIGN_UP.path} />
        </>
      }
      {
        !isBusinessCreated &&
        <>
          <Route path={BUSINESS_CREATION.path} component={BusinessCreation}/>
          <Redirect to={BUSINESS_CREATION.path} />
        </>
      }
      <Route path={WELCOME.path} component={Welcome}/>
      <Route path={VERIFICATION_IS_SENT.path} component={VerificationIsSent}/>
      <Redirect to={WELCOME.path} />
    </Switch>
  )
}

export default AppRoutes;
