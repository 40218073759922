import React, { useRef, useState } from 'react';
import cs from 'classnames';
import './PhotoCarousel.css'
import leftArrowImage from '../../../assets/left-arrow.svg';
import rightArrowImage from '../../../assets/right-arrow.svg';

export interface PhotoCarouselProps {
  images: Array<string>;
  infinite?: boolean;
}

const PhotoCarousel: React.FC<PhotoCarouselProps> = function ({ images, infinite= false }) {
  const [currentPhoto, setCurrentPhoto] = useState(1);
  const carouselContainer = useRef<HTMLDivElement>(null);

  const hasNext = currentPhoto < images.length;
  const hasPrev = currentPhoto > 1;

  const nextPhoto = (e: any) => {
    e.stopPropagation();
    if (!hasNext && !infinite) {
      return;
    }
    setCurrentPhoto(hasNext ? currentPhoto + 1 : 1);
  };

  const prevPhoto = (e: any) => {
    e.stopPropagation();
    if (!hasPrev && !infinite) {
      return;
    }
    setCurrentPhoto(hasPrev ? currentPhoto - 1 : images.length);
  };


  const carouselWidth = carouselContainer.current?.clientWidth || 0;
  const shift = carouselWidth * (currentPhoto - 1);

  return (
    <div className="photo-carousel" ref={carouselContainer}>
      {
        images.map((image, index) => {
          const marginLeft = index === 0 ? -shift : 0;
          return (
            <div
              key={image}
              className="photo-carousel__image"
              style={{ marginLeft }}
            >
              <img src={image} alt="" />
            </div>
          )
        })
      }
      <button className={cs('photo-carousel__left-btn', { 'photo-carousel__hidden': !hasPrev && !infinite })} onClick={prevPhoto}>
        <div className="photo-carousel__left">
          <img src={leftArrowImage} alt="" />
        </div>
      </button>
      <button className={cs('photo-carousel__right-btn', { 'photo-carousel__hidden': !hasNext && !infinite })} onClick={nextPhoto}>
        <div className="photo-carousel__right">
          <img src={rightArrowImage} alt="" />
        </div>
      </button>

    </div>
  )
}

export default PhotoCarousel;
