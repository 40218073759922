
class StorageService {
  KEYS = {
    ONBOARDING_PASSED: 'onboardingPassed',
    TOKEN: 'token',
  }

  set(key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  get(key: string): string | null {
    return localStorage.getItem(key);
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }
}

export default new StorageService();
