import { isPhoneNumber } from './validation';

export function getPhoneOrEmail(login: string): { email?: string, phone?: string } {
  let phone;
  let email;
  if (isPhoneNumber(login) || isPhoneNumber(`+${login}`)) {
    if (login[0] !== '+') {
      phone = `+${login}`;
    } else {
      phone = login;
    }
  } else {
    email = login;
  }
  return {
    email,
    phone,
  }
}
