import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from './store/store';
import AppRouters from './routes';
import './utils/lang/i18n';
import 'react-toastify/dist/ReactToastify.css';
import GlobalSpinner from './components/GlobalSpinner';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppRouters />
        <ToastContainer />
      </Router>
      <GlobalSpinner />
    </Provider>
  );
}

export default App;
