import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import p from '../../../assets/p.svg';
import { TextInput, TextArea } from '../index';
import './MapPicker.css'
import crossMark from '../../../assets/cross.svg';

type Props = {
  zoom?: number;
  onChangeLocation?(location: google.maps.LatLngLiteral | null): void;
  location?: google.maps.LatLngLiteral;
}

const DEFAULT_ZOOM = 10;
export const DEFAULT_LOCATION = { lat: 53.893009, lng: 27.567444 }; // Minsk


const MapPicker: FC<Props> = ({ zoom = DEFAULT_ZOOM, onChangeLocation, location }) => {
  const { t } = useTranslation();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);
  const [geo, setGeo] = useState<google.maps.Geocoder | null>(null);
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null);
  const mapContainer = useRef<HTMLDivElement>(null);
  const textArea = useRef<HTMLTextAreaElement>(null);
  const textInputContainer = useRef<HTMLDivElement>(null);
  const doneBtn = useRef<HTMLDivElement>(null);
  const [address, setAddress] = useState('');

  const handleChangeAddress = useCallback((value) => {
    setAddress(value);
    if (!value) {
      if (onChangeLocation) {
        onChangeLocation(null);
      }
      if (marker) {
        marker.setVisible(false);
      }
    }
  }, [onChangeLocation, marker]);

  const closeMap = useCallback(function closeMap() {
    if (!mapContainer.current) {
      return;
    }
    mapContainer.current.style.visibility = 'hidden';
  }, [mapContainer])

  const toggleFullScreen = useCallback(async function toggleFullScreen() {
    if (!mapContainer.current) {
      return;
    }
    const mapVisibility = getComputedStyle(mapContainer.current).visibility;
    if (mapVisibility === 'hidden') {
      map?.setCenter(location || DEFAULT_LOCATION);
      map?.setZoom(zoom);
      mapContainer.current.style.visibility = 'visible';
    } else {
      mapContainer.current.style.visibility = 'hidden';
    }
  }, [map, location, mapContainer, zoom]);

  const fromCoordsToAddress = useCallback(async function getAddress(location: google.maps.LatLng | google.maps.LatLngLiteral) {
    if (!geo) {
      return '';
    }
    const { results: [{ formatted_address: formattedAddress }] } = await geo.geocode({ location });
    return formattedAddress;
  }, [geo])

  const handleChangeLocation = useCallback(async function handleChangeLocation() {
    if (!marker) {
      return;
    }
    const currentLocation = marker.getPosition();
    if (!currentLocation) {
      return;
    }

    if (onChangeLocation) {
      onChangeLocation({ lat: currentLocation.lat(), lng: currentLocation.lng() });
    }

    setAddress(await fromCoordsToAddress(currentLocation));
  }, [marker, onChangeLocation, fromCoordsToAddress]);

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }
    const _map = new google.maps.Map(mapContainer.current,
      {
        center: DEFAULT_LOCATION,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        gestureHandling: 'greedy',
        zoom: DEFAULT_ZOOM,
      });
    setMap(_map);
    const _marker = new google.maps.Marker({
      map: _map,
      draggable: true,
      icon: p,
    });
    setMarker(_marker);
    const _geo = new google.maps.Geocoder();
    setGeo(_geo);
    if (textArea.current) {
      const _searchBox = new google.maps.places.SearchBox(textArea.current as any);
      setSearchBox(_searchBox)
    }
  }, [mapContainer])

  useEffect(() => {
    if (!map) {
      return;
    }
    if (!doneBtn.current || !textInputContainer.current) {
      return;
    }

    const doneBtnIndex = map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(doneBtn.current) - 1;
    const searchBoxIndex = map.controls[google.maps.ControlPosition.TOP_LEFT].push(textInputContainer.current) - 1;

    return () => {
      map.controls[google.maps.ControlPosition.BOTTOM_CENTER].removeAt(doneBtnIndex);
      map.controls[google.maps.ControlPosition.TOP_LEFT].removeAt(searchBoxIndex);
    }
  }, [map, marker, t, toggleFullScreen, textInputContainer, doneBtn]);

  useEffect(() => {
    if (!mapContainer.current || !map || !marker || !searchBox) {
      return;
    }

    const dragstartListener = google.maps.event.addListener(marker, 'dragend', handleChangeLocation);
    const clickListener = map.addListener('click', function (event: google.maps.MapMouseEvent) {
      const clickedLocation = event.latLng;
      marker.setPosition(clickedLocation);
      marker.setVisible(true);
      handleChangeLocation();
    });

    const boundsListener = map.addListener('bounds_changed', () => {
      searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
    });

    const placeChangedListener = searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (!places || !places.length) {
        return;
      }

      // For each place, get the icon, name and location.
      const place = places.find((place) => place.geometry && place.geometry.location);
      if (!place) {
        return;
      }
      const { geometry } = place;
      if (!geometry || !geometry.location) {
        return;
      }

      marker?.setPosition(geometry.location);
      marker.setVisible(true);
      handleChangeLocation();
      const bounds = new google.maps.LatLngBounds();
      if (geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(geometry.viewport);
      } else {
        bounds.extend(geometry.location);
      }
      map.fitBounds(bounds);
    });
    return () => {
      google.maps.event.removeListener(dragstartListener);
      google.maps.event.removeListener(clickListener);
      google.maps.event.removeListener(boundsListener);
      google.maps.event.removeListener(placeChangedListener);
    }
  }, [map, marker, searchBox, handleChangeLocation, t, toggleFullScreen, zoom]);

  useEffect(() => {
    if (!marker) {
      return;
    }
    marker.setPosition(location);
    marker.setVisible(true);
    if (location) { // check if address has been already chosen at least once
      fromCoordsToAddress(new google.maps.LatLng(location)).then(setAddress);
    }
  }, [marker, location, fromCoordsToAddress])


  useEffect(() => {
    if (map) {
      map.setZoom(zoom);
    }
  }, [zoom, map]);

  useEffect(() => {
    const listener = (event: Event) => {
      const isFullScreen = !!document.fullscreenElement
      alert(isFullScreen);
      if (!isFullScreen) {
        closeMap();
      }
      // Fixing autocomplete in full screen
      let target = (event.target) as HTMLDivElement;
      let pacContainer = document.getElementsByClassName('pac-container')[0];
      if (pacContainer) {
        if (pacContainer.parentElement === target) {
          document.getElementsByTagName('body')[0].appendChild(pacContainer);
          pacContainer.classList.remove('fullscreen-pac-container');
        } else {
          target?.appendChild(pacContainer);
          pacContainer.classList.add('fullscreen-pac-container');
        }
      }

    };
    document.addEventListener('fullscreenchange', listener);

    return () => document.removeEventListener('fullscreenchange', listener);
  }, [mapContainer, closeMap])


  const clear = useCallback(() => {
    handleChangeAddress('');
  }, [handleChangeAddress]);

  return (
    <>
      <TextInput
        value={address}
        readOnly
        placeholder={t('viewp.common.map.placeholder')}
        onClick={toggleFullScreen}
      />
      <div ref={mapContainer} className="map__container">
        <div ref={doneBtn}>
          <div className="map-choose__container" onClick={toggleFullScreen}>
            <div className="map-choose__text">
              {t('viewp.common.map.done')}
            </div>
          </div>
        </div>

        <div ref={textInputContainer} className="search-bar__container">
          <p className="search-bar__text">{t('viewp.common.map.chosen-address')}</p>
          <TextArea
            value={address}
            onChangePlain={handleChangeAddress}
            className="search-bar__input"
            customRef={textArea}
            placeholder={t('viewp.common.map.placeholder')}
          />
          <img
            src={crossMark}
            alt="clear"
            onClick={clear}
            className="search-bar__clear"
          />
        </div>
      </div>
    </>
  );
};

export default MapPicker;
