import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { passOnboarding as passOnboardingAction } from '../../store/appSettingsSlice'
import onBoardingImg1 from '../../assets/onboarding-1.svg';
import onBoardingImg2 from '../../assets/onboarding-2.svg';
import onBoardingImg3 from '../../assets/onboarding-3.svg';
import storageService from '../../services/StorageService';
import logo from '../../assets/logo.svg';
import { Carousel, Button } from '../../components/common';
import OnboardingSlide from '../../components/OnboardingSlide';
import Link from '../../components/common/Link';
import { DETAILS, LOGIN } from '../../constants/routes';
import './Onboarding.css'

const slidesImages: Array<string> = [onBoardingImg1, onBoardingImg2, onBoardingImg3];

const Onboarding: React.FC = function() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const carouselSlides: Array<React.ReactChild> = slidesImages.map((image, index) => (
    <OnboardingSlide
      key={index}
      image={image}
      descriptionTitleText={t(`viewp.onboarding.descriptionTitle${index + 1}`)}
      descriptionText={t(`viewp.onboarding.description${index + 1}`)}
    />
  ))

  const passOnboarding = useCallback(() => {
    storageService.set(storageService.KEYS.ONBOARDING_PASSED, 'true');
    dispatch(passOnboardingAction());
    history.push(LOGIN.path);
  }, [history, dispatch]);

  return (
    <div className="onboarding__container">
      <figure className="onboarding__logo">
        <img src={logo} alt=""/>
      </figure>
      <h1 className="onboarding__title">{t('viewp.onboarding.platform')}</h1>
      <div className="onboarding__sub-container">
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay={false}
          thumbWidth={200}
          emulateTouch
          swipeScrollTolerance={100}
          infiniteLoop
          interval={9999999999} // To fix autoplay
        >
          {carouselSlides}
        </Carousel>
        <Link className="onboarding__link" to={DETAILS.path}>
          {t('viewp.onboarding.link.details')}
        </Link>
      </div>
      <Button className="onboarding__button" onClick={passOnboarding}>
        {t('viewp.onboarding.button.signup-signin')}
      </Button>
    </div>
  )
}

export default Onboarding;