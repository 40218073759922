import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo.svg';
import { Button } from '../../components/common';
import './Login.css'
import Link from '../../components/common/Link';
import { SIGN_IN, SIGN_UP } from '../../constants/routes';


const Login: React.FC = function() {
  const { t } = useTranslation();

  return (
    <div className="login__container">
      <figure className="login__logo">
        <img src={logo} alt=""/>
      </figure>
      <div className="login__sub-container">
        <p className="login__note" dangerouslySetInnerHTML={{ __html: t('viewp.login.note') }} />
        <Button className="login__button" to={SIGN_UP.path}>
          {t('viewp.login.signup')}
        </Button>
        <Link className="login__link" to={SIGN_IN.path}>
          {t('viewp.login.signin')}
        </Link>
      </div>
    </div>
  )
}

export default Login;