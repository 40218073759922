import cs from 'classnames';
import React  from 'react';
import { ItemComponentProps } from '../common/RadioSelect/RadioSelect';
import checkMark from '../../assets/check-mark.svg';
import { IBusinessType } from '../../pages/BusinessCreation/BusinessCreation';
import './BusinessType.css'

const BusinessType: React.FC<ItemComponentProps<IBusinessType>> = function ({
  item,
  checked,
}) {
  const { name, image, description } = item;

  return (
    <div className={cs('business-type__container', { 'business-type__container--checked': checked })}>
      <div className="business-type__content">
        <div className={cs('business-type__checkbox', { 'business-type__checkbox--checked': checked })}>
          <img className={cs('business-type__checkmark', { 'business-type__checkmark--checked': checked })} src={checkMark} alt=""/>
        </div>
        <h1 className="business-type__name">{name}</h1>
        <p className="business-type__description">{description}</p>
      </div>
      <figure className="business-type__image">
        <img src={image} alt=""/>
      </figure>
    </div>
  )
}


export default BusinessType;
