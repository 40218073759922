import React, {
  useCallback,
  useEffect,
} from 'react';
import cs from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import './TextArea.css'
import { TextareaAutosizeProps } from 'react-textarea-autosize/dist/declarations/src';

export interface InputProps extends TextareaAutosizeProps {
  onChangePlain?: (value: string) => void,
  phone?: boolean,
  invalid?: boolean,
  multiline?: boolean,
  customRef?: React.RefObject<HTMLTextAreaElement>
}

const TextArea: React.FC<InputProps> = function ({
  children ,
  className,
  onChangePlain = () => {},
  onChange: _onChange = () => {},
  value,
  invalid,
  phone,
  customRef,
  multiline = false,
  ...props
}) {
  const onChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>((event) => {
    _onChange(event);
    onChangePlain(event.target.value);
  }, [onChangePlain, _onChange]);
  useEffect(() => {
    const stringifiedValue = `${value || ''}`;
    onChangePlain(stringifiedValue);
  }, [phone, value, onChangePlain]);

  // Implicitly say to browser that autocomplete must be disabled
  if (customRef?.current) {
    customRef.current.setAttribute('autocomplete', 'nope');
  }

  return (
    <div className="textarea__container">
      <TextareaAutosize
        autoComplete="nope"
        ref={customRef}
        className={cs('textarea', className)}
        onChange={onChange}
        value={value}
        {...props}
      >
        {children}
      </TextareaAutosize>
    </div>
  )
}

export default TextArea;
