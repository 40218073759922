import React, { ButtonHTMLAttributes, useCallback } from 'react';
import cs from 'classnames';
import { useHistory } from 'react-router-dom';
import './Button.css'


export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  to?: string,
}

const Button: React.FC<ButtonProps> = function ({
  children ,
  className,
  onClick,
  to,
  ...props
}) {
  const history = useHistory();
  const defaultOnClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(() => {
    if (to) {
      history.push(to);
    }
  }, [to, history]);

  return (
    <button className={cs('button', className)} onClick={to ? defaultOnClick : onClick} {...props}>
      {children}
    </button>
  )
}


export default Button;
