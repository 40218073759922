import { configureStore } from '@reduxjs/toolkit'
import appSettingsReducer from './appSettingsSlice'
import currentUserReducer from './currentUserSlice';
import { viewpApi } from './viewpApiSlice';
import { userApi } from './userApiSlice';
import { authMiddleware } from './authMidleware'

const store = configureStore({
  reducer: {
    appSettings: appSettingsReducer,
    currentUser: currentUserReducer,
    [viewpApi.reducerPath]: viewpApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(viewpApi.middleware, userApi.middleware, authMiddleware)
})
export default store;

export type RootState = ReturnType<typeof store.getState>;
