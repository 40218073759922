import './OnboardingSlide.css'
import React from 'react';

export interface OnboardingSlideProps {
  image: string,
  descriptionTitleText: string,
  descriptionText: string
}

const OnboardingSlide: React.FC<OnboardingSlideProps> = function ({ image, descriptionTitleText, descriptionText }) {
  return (
    <div className="slide__container">
      <figure className="slide__img">
        <img src={image} alt=""/>
      </figure>
      <p className="slide__description-title">{descriptionTitleText}</p>
      <p className="slide__description">{descriptionText}</p>
    </div>
  )
}


export default OnboardingSlide;
