import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Props } from 'react-responsive-carousel/lib/ts/components/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.css'

const CustomCarousel: React.FC<Partial<Props>> = function ({ children , ...props}) {
  return (
    <Carousel
      {...props}
    >
      {children}
    </Carousel>
  )
}

export default CustomCarousel;
