import cs from 'classnames';
import React  from 'react';
import { ItemComponentProps } from '../common/RadioSelect/RadioSelect';
import { ICategory } from '../../services/ViewpService';
import { firstLetterCapitalize } from '../../utils/string';
import './BusinessCategory.css';

const BusinessCategory: React.FC<ItemComponentProps<ICategory>> = function ({
  item,
  checked,
}) {
  return (
    <div className={cs('business-category__container', { 'business-category__container--checked': checked })}>
      <figure className="business-category__image">
        <img src={item.imageUrl} alt=""/>
      </figure>
      <span className={cs('business-category__text', { 'business-category__text--checked': checked })}>
        {firstLetterCapitalize(item.text)}
      </span>
    </div>
  )
}


export default BusinessCategory;
