import React, { useCallback, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logIn } from '../../store/currentUserSlice';
import { enableLoading, disableLoading } from '../../store/appSettingsSlice';
import leftArrow from '../../assets/left-long-arrow.svg';
import logo from '../../assets/logo.svg';
import { Button, InlineButton } from '../../components/common';
import UserService from '../../services/UserService';
import TextInput from '../../components/common/TextInput';
import { FORGOT_PASSWORD, LOGIN, WELCOME } from '../../constants/routes';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { isEmail } from '../../utils/validation';
import { toast } from 'react-toastify';
import './SignIn.css'


const SignIn: React.FC = function() {
  const { t } = useTranslation();
  const [login, setLogin] = useState<string>('');
  const [loginValid, setLoginValidity] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordValid, setPasswordValidity] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const onChangeLogin = useCallback((value: string) => {
    const isValid = isValidPhoneNumber(value) || isValidPhoneNumber(`+${value}`) || isEmail(value);
    setLogin(value);
    setLoginValidity(isValid);
  }, [setLogin]);

  const onChangePassword = useCallback((value: string, isValid) => {
    setPassword(value)
    setPasswordValidity(isValid);
  }, [setPassword]);

  const onSignIn = useCallback(async () => {
    dispatch(enableLoading());
    try {
      const token = await UserService.signIn(login, password);
      dispatch(logIn(token));
      history.push(WELCOME.path);
    } catch (err) {
      if (err.isAxiosError && err.response.status === 400) {
        toast.error(t('viewp.signin.error'));
      } else {
        toast.error(t('viewp.error.default'));
      }
    }
    dispatch(disableLoading());

  }, [login, password, history, dispatch, t]);

  const goBack = useCallback(() => {
    history.push(LOGIN.path);
  }, [history]);

  const toForgotPasswordPage = useCallback(() => {
    history.push(FORGOT_PASSWORD.path);
  }, [history]);

  return (
    <div className="signin__container">
      <div className="signin__header">
        <figure
          onClick={goBack}
          className="signin__back"
        >
          <img src={leftArrow} alt=""/>
        </figure>
        <figure className="signin__logo">
          <img src={logo} alt=""/>
        </figure>
      </div>
      <div className="signin__main-input-container">
        <p className="signin__label">
          {t('viewp.signin.login-text')}
        </p>
        <div className="signin__input-container">
          <TextInput
            value={login}
            invalid={!loginValid && !!login}
            placeholder={t('viewp.signin.login')}
            onChangePlain={onChangeLogin}
          />
          <TextInput
            value={password}
            type="password"
            placeholder={t('viewp.signin.password')}
            onChangePlain={onChangePassword}
            minLength={8}
          />
        </div>
      </div>
      <div className="signin-bts-container">
        <Button
          disabled={!passwordValid || !password || !login || !loginValid}
          className="signin__button"
          onClick={onSignIn}
        >
          {t('viewp.signin.button')}
        </Button>
        <InlineButton
          className={'signin__forgot-pass-btn'}
          onClick={toForgotPasswordPage}>
          {t('viewp.signin.forgot-pass')}
        </InlineButton>
      </div>
    </div>
  )
}

export default SignIn;
