import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import placeImage from '../../assets/place.svg';
import workerImage from '../../assets/worker.svg';
import eventImage from '../../assets/event.svg';
import { Button, Stepper, PhotoPicker, RadioSelect, TextInput, MapPicker } from '../../components/common';
import { BUSINESS_TYPES } from '../../constants/business';
import { VERIFICATION_IS_SENT } from '../../constants/routes';
import { detectLanguage } from '../../utils/lang/i18n';
import { useGetCategoriesQuery } from '../../store/viewpApiSlice';
import { ICategory } from '../../services/ViewpService';
import BusinessType from '../../components/BusinessType';
import BusinessCategory from '../../components/BusinessCategory';
import { disableLoading, enableLoading } from '../../store/appSettingsSlice';
import BusinessService from '../../services/BusinessService';
import './BusinessCreation.css';
import { FileContent } from 'use-file-picker/dist/interfaces';
import UtilitiesService from '../../services/UtilitiesService';


export interface IBusinessType {
  id: number;
  image: string;
  name: string;
  description: string;
}

const STEPS_LENGTH = 4;

const BusinessCreation: React.FC = function () {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [enabledNextStep, setEnabledNextStep] = useState<boolean>(false);
  const [enabledPrevStep, setEnabledPrevStep] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [businessType, setBusinessType] = useState<number | null>(null);
  const [businessCategory, setBusinessCategory] = useState<number | null>(null);
  const [categories, setCategories] = useState<Array<ICategory>>([]);
  const [juridicalName, setJuridicalName] = useState('');
  const [juridicalNameValid, setJuridicalNameValidity] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [businessNameValid, setBusinessNameValidity] = useState(false);
  const [nickname, setNickname] = useState('');
  const [nicknameValid, setNicknameValidity] = useState(false);
  const [phone, setPhone] = useState('');
  const [location, setLocation] = useState<google.maps.LatLngLiteral | null>();
  const [phoneValid, setPhoneValidity] = useState(false);
  const [businessPhotos, setBusinessPhotos] = useState<FileContent[]>([]);
  const [verificationPhotos, setVerificationPhotos] = useState<FileContent[]>([]);

  const onNextStep = useCallback(async () => {
    if (step + 1 < STEPS_LENGTH){
      setStep(step + 1);
      return;
    }
    if (!businessType || !businessCategory || !location) {
      toast.error(t('viewp.business-creation.validation-error'))
      return;
    }
    dispatch(enableLoading());
    try {
      const [businessPhotosUrls, verificationPhotosUrls] = await Promise.all([
        Promise.all(businessPhotos.map((photo) => UtilitiesService.uploadImage(photo))),
        Promise.all(verificationPhotos.map((photo) => UtilitiesService.uploadImage(photo)))
      ])

      await BusinessService.createBusiness({
        type: businessType,
        category: businessCategory,
        juridicalName,
        businessUnit: {
          location,
          name: businessName,
          nickname,
          phone,
          photos: businessPhotosUrls,
          verificationPhotos: verificationPhotosUrls,
        }
      });
      history.push(VERIFICATION_IS_SENT.path);
    } catch (err) {
      toast.error(t('viewp.business-creation.error.business-creation'));
    }

    dispatch(disableLoading());
  }, [
    setStep, step, dispatch, history, businessCategory, verificationPhotos, location,
    juridicalName, nickname, businessType, t, phone, businessName, businessPhotos
  ]);
  const onPrevStep = useCallback(() => {
    setStep(step - 1);
  }, [setStep, step]);
  const onBusinessTypeChange = useCallback((businessType: IBusinessType) => {
    setBusinessType(businessType.id);
  }, [setBusinessType]);
  const onBusinessCategoryChange = useCallback((category: ICategory) => {
    setBusinessCategory(category.id);
  }, [setBusinessCategory]);
  const onJuridicalNameChange = useCallback((name, isValid) => {
    setJuridicalName(name);
    setJuridicalNameValidity(isValid);
  }, []);
  const onBusinessNameChange = useCallback((name, isValid) => {
    setBusinessName(name);
    setBusinessNameValidity(isValid);
  }, [setBusinessName]);
  const onNicknameChange = useCallback((nick, isValid) => {
    setNickname(nick);
    setNicknameValidity(isValid);
  }, [setNickname]);
  const onPhoneChange = useCallback((phoneNumber, isValid) => {
    setPhone(phoneNumber);
    setPhoneValidity(isValid);
  }, [setPhone]);
  const onLocationChange = useCallback((location) => {
    setLocation(location);
  }, [setLocation]);
  const onBusinessImagesChange = useCallback((images) => {
    setBusinessPhotos(images);
  }, [setBusinessPhotos]);
  const onVerificationImagesChange = useCallback((images) => {
    setVerificationPhotos(images);
  }, [setVerificationPhotos]);

  const language = detectLanguage(i18n);
  const {data: allCategories, error} = useGetCategoriesQuery(language);
  if (error) {
    toast.error(t('viewp.business-creation.error.category-loading'));
  }

  useEffect(() => {
    if (allCategories && typeof businessType === 'number') {
      const categoriesByBusinessType = allCategories.find(({id}) => id === businessType)?.categories;
      if (categoriesByBusinessType) {
        setCategories(categoriesByBusinessType);
      }
    }
  }, [allCategories, businessType, setCategories])

  const updateNavBtnsState = useCallback(() => {
    const enabledNextStepNew = (step === 0 && businessType !== null) ||
      (step === 1 && businessCategory !== null) ||
      (step === 2 && nickname && nicknameValid && phone && phoneValid && businessName && businessNameValid && !!location) ||
      (step === 3 && juridicalName && juridicalNameValid && !!verificationPhotos.length) || false;
    const enabledPrevStepNew = (step > 0);
    if (enabledNextStepNew !== enabledNextStep) {
      setEnabledNextStep(enabledNextStepNew);
    }
    if (enabledPrevStepNew !== enabledPrevStep) {
      setEnabledPrevStep(enabledPrevStepNew);
    }
  }, [step, businessType, businessCategory, enabledNextStep, enabledPrevStep, setEnabledPrevStep,
    setEnabledNextStep,nicknameValid, businessNameValid, phoneValid, businessName, nickname, phone,
    location, juridicalName, juridicalNameValid, verificationPhotos.length])
  updateNavBtnsState();

  const cards = useMemo(() => {
    return [{
      id: BUSINESS_TYPES.PLACE,
      name: t('viewp.business-creation.place'),
      description: t('viewp.business-creation.place.description'),
      image: placeImage,
    }, {
      id: BUSINESS_TYPES.WORKER,
      name: t('viewp.business-creation.worker'),
      description: t('viewp.business-creation.worker.description'),
      image: workerImage,
    }, {
      id: BUSINESS_TYPES.EVENT,
      name: t('viewp.business-creation.event'),
      description: t('viewp.business-creation.event.description'),
      image: eventImage,
    }];
  }, [t]);

  const steps = [(
    <div className="business-creation__step-container">
      <h1 className="business-creation__title">{t('viewp.business-creation.title.step.1')}</h1>
      <p className="business-creation__subtitle">{t('viewp.business-creation.subtitle.step.1')}</p>
      <RadioSelect
        className="business-types-list"
        itemContainerClassName="business-type"
        values={cards}
        onChange={onBusinessTypeChange}
        selectedValue={businessType || undefined}
        ItemComponent={BusinessType}
      />
    </div>
  ), (
    <div className="business-creation__step-container">
      <h1 className="business-creation__title">{t(`viewp.business-creation.title.step.2.business.${businessType}`)}</h1>
      <p className="business-creation__subtitle">{t('viewp.business-creation.subtitle.step.2')}</p>
      <RadioSelect
        className="business-categories-list"
        itemContainerClassName="business-category"
        values={categories}
        onChange={onBusinessCategoryChange}
        selectedValue={businessCategory || undefined}
        ItemComponent={BusinessCategory}
      />
    </div>
  ), (
    <div className="additional-info__container">
      <h1 className="additional-info__title">{t('viewp.business-creation.title.step.3')}</h1>
      <p className="additional-info__description">{t('viewp.business-creation.additional-info.description')}</p>
      <p className="nickname__description">{t('viewp.business-creation.nickname')}</p>
      <TextInput
        onChangePlain={onNicknameChange}
        value={nickname}
        className="nickname__input"
        placeholder={t('viewp.business-creation.nickname.placeholder')}
        pattern="[a-zA-Z0-9-_]+"
        minLength={3}
        maxLength={20}
      />
      <p className="business-name__description">{t('viewp.business-creation.business-name')}</p>
      <TextInput
        onChangePlain={onBusinessNameChange}
        value={businessName}
        className="business-name__input"
        placeholder={t('viewp.business-creation.business-name.placeholder')}
        pattern="[a-zA-Zа-яА-Я0-9-_ ]+"
        minLength={3}
        maxLength={50}
      />
      <p className="address__description">{t('viewp.business-creation.address')}</p>
      <MapPicker
        location={location || undefined}
        onChangeLocation={onLocationChange}
      />
      <PhotoPicker
        images={businessPhotos}
        onChangeImages={onBusinessImagesChange}
        className="business__photo-picker"
        limitFilesConfig={{ min: 1, max: 20 }}
        maxFileSize={10}
        restrictionsText={t('viewp.business-creation.verification.restrictions')}
      />
      <p className="phone-number">{t('viewp.business-creation.phone')}</p>
      <TextInput
        onChangePlain={onPhoneChange}
        value={phone}
        className="phone__input"
        phone
      />
    </div>
  ), (
    <div className="verification__container">
      <h1 className="business-creation__title">{t('viewp.business-creation.title.step.4')}</h1>
      <p className="verification__description">{t('viewp.business-creation.verification.description')}</p>
      <PhotoPicker
        images={verificationPhotos}
        onChangeImages={onVerificationImagesChange}
        className="verification__photo-picker"
        limitFilesConfig={{ min: 1, max: 20 }}
        maxFileSize={10}
        restrictionsText={t('viewp.business-creation.verification.restrictions')}
      />
      <p className="juridical-name__description">{t('viewp.business-creation.juridical-name')}</p>
      <TextInput
        onChangePlain={onJuridicalNameChange}
        value={juridicalName}
        className="juridical-name__input"
        placeholder={t('viewp.business-creation.juridical-name.placeholder')}
        pattern="[a-zA-Zа-яА-Я0-9-_ \x22]+"
        minLength={3}
        maxLength={50}
      />
    </div>
  )];

  return (
    <div className="business-creation__container">
      <Stepper steps={steps} currentStep={step}/>
      <div className="navigation">
        <div className="navigation__content">
          <Button
            onClick={onPrevStep}
            disabled={!enabledPrevStep}
            className="navigation__button navigation__button--back"
          >
            {t('viewp.business-creation.nav.back')}
          </Button>
          <span className="navigation__steps">{step + 1} {t('viewp.business-creation.nav.step')} 4</span>
          <Button
            disabled={!enabledNextStep}
            onClick={onNextStep}
            className="navigation__button navigation__button--next"
          >
            {
              step === STEPS_LENGTH - 1
              ? t('viewp.business-creation.nav.done')
              : t('viewp.business-creation.nav.next')
            }
          </Button>
        </div>
      </div>

    </div>
  )
}

export default BusinessCreation;
