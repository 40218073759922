import StorageService from './StorageService';


class TokenService {
  private token: string = '';
  constructor() {
    const token = StorageService.get(StorageService.KEYS.TOKEN);
    if (token) {
      this.token = token;
    }
  }
  setToken(token: string): void {
    StorageService.set(StorageService.KEYS.TOKEN, token);
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  isAuth() {
    return !!this.token;
  }

}

export default new TokenService();