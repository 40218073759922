import { useFilePicker } from 'use-file-picker';
import React, { HTMLAttributes, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import placeholderImage from '../../../assets/camera.svg';
import paperClip from '../../../assets/paper_clip.svg';
import './PhotoPicker.css'
import { FileContent, UseFilePickerConfig } from 'use-file-picker/dist/interfaces';
import { PhotoCarousel } from '../index';

interface PhotoPickerProps extends HTMLAttributes<HTMLDivElement>, UseFilePickerConfig {
  restrictionsText?: string;
  images?: FileContent[];
  onChangeImages?: (images: FileContent[]) => void;
}

const PhotoPicker: React.FC<PhotoPickerProps> = function ({
  children ,
  className,
  onClick,
  onChangeImages,
  multiple = true,
  readAs = 'DataURL',
  accept= ['.png', '.svg', '.jpg', '.jpeg'],
  limitFilesConfig,
  minFileSize,
  maxFileSize,
  readFilesContent,
  restrictionsText,
  images= [],
  ...props
}) {
  const { t } = useTranslation();
  const [
    openFileSelector,
    { filesContent, loading, errors }
  ] = useFilePicker({
    multiple,
    readAs,
    accept,
    limitFilesConfig,
    minFileSize,
    maxFileSize,
    readFilesContent,
  });

  useEffect(() => {
    if (onChangeImages) {
      onChangeImages(filesContent);
    }
  }, [onChangeImages, filesContent]);

  const errorsTexts = useMemo(() =>
    errors
      .map((error) => {
        if (error.fileSizeToolarge) {
          return t('viewp.common.photo.errors.too-large')
        } else if (error.fileSizeTooSmall) {
          return t('viewp.common.photo.errors.too-small')
        } else if (error.imageHeightTooBig) {
          return t('viewp.common.photo.errors.height-too-big')
        } else if (error.imageHeightTooSmall) {
          return t('viewp.common.photo.errors.height-too-small')
        } else if (error.imageNotLoaded) {
          return t('viewp.common.photo.errors.not-loaded')
        } else if (error.imageWidthTooBig) {
          return t('viewp.common.photo.errors.width-too-big')
        } else if (error.imageWidthTooSmall) {
          return t('viewp.common.photo.errors.width-too-small')
        } else if (error.maxLimitExceeded) {
          return t('viewp.common.photo.errors.max-limit')
        } else if (error.minLimitNotReached) {
          return t('viewp.common.photo.errors.min-limit')
        } else if (error.readerError) {
          return t('viewp.common.photo.errors.reader-error')
        }
        return null;
      })
      .filter(item => item),
    [errors, t]
  );

  const imagesContent = useMemo(() => {
    return images.map(({ content }) => content);
  }, [images]);

  return (
    <>
      <div className={cs('photo-picker', className)} onClick={openFileSelector} {...props}>
        {
          !!images.length && !errors.length
            && <PhotoCarousel images={imagesContent} infinite />
        }
        {
          !images.length && !errors.length && !loading &&
          <>
            <img className="photo-picker__image" src={placeholderImage} alt=""/>
            <span className="photo-picker__text">{t('viewp.common.add-photo')}</span>
          </>
        }
        {
          loading && <span className="photo-picker__loading">{t('viewp.common.loading')}</span>
        }
        {
          !!errors.length
            && <span className="photo-picker__text photo-picker__text--error">{errorsTexts.join('\n')}</span>
        }
      </div>
      {
        restrictionsText && <span className="photo-picker__restrictions">{restrictionsText}</span>
      }
      {
        !!images.length &&
        <div className="files-count-container" onClick={openFileSelector}>
          <img className="files-count-container__paper-clip" src={paperClip} alt=""/>
          <span className="files-count-container__text">{t('viewp.common.photos-number', { number: images.length })}</span>
        </div>
      }
    </>
  )
}


export default PhotoPicker;
