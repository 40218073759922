import React from 'react';
import './Stepper.css'

interface StepperProps {
  steps: Array<React.ReactNode>,
  currentStep: number
};


const Stepper: React.FC<StepperProps> = function ({
  steps: componentSteps,
  currentStep = 0
}) {

  return (
    <>
      {componentSteps[currentStep]}
    </>
  )
}

export default Stepper;
