import BaseApiService from './BaseApiService';

interface IBusinessData {
  type: number,
  category: number,
  juridicalName: string,
  businessUnit: {
    nickname: string,
    name: string,
    photos: Array<string>
    verificationPhotos: Array<string>
    location: { lat: number, lng: number },
    phone: string
  }
}

class BusinessService extends BaseApiService {
  constructor() {
    super('businesses');
  }

  async createBusiness(businessData: IBusinessData): Promise<void> {
    let phone = businessData.businessUnit.phone
    if (businessData.businessUnit.phone[0] !== '+') {
      phone = `+${phone}`;
    }
    await this.serviceWithAuth.post('/', {
      ...businessData,
      businessUnit: {
        ...businessData.businessUnit,
        phone,
      },
    });
  }
}

export default new BusinessService();
