import React, { useCallback, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import cs from 'classnames';
import { logIn } from '../../store/currentUserSlice';
import { useSetPasswordMutation } from '../../store/userApiSlice';
import { disableLoading, enableLoading } from '../../store/appSettingsSlice';
import logo from '../../assets/logo.svg';
import leftArrow from '../../assets/left-long-arrow.svg';
import { Button, TextInput, Select, Stepper, InlineButton } from '../../components/common';
import { HOME, LOGIN } from '../../constants/routes';
import { PHONE_CODES } from '../../constants/phone';
import UserService from '../../services/UserService';
import { isEmail, isPasswordValid, isPhoneNumber } from '../../utils/validation';
import { DIRECTIONS, useTimer } from '../../utils/hooks/timer';
import './SignUp.css';


const phoneCodesOptions = PHONE_CODES.map(code => ({ value: code, label: `+${code}` }));
const defaultOption = { value: '375', label: '+375' };
const CODE_LENGTH = 6;
const RETRY_TIME = 60;

export enum STEPS {
  PHONE = 0,
  PHONE_CODE = 1,
  EMAIL = 2,
  EMAIL_CODE = 3,
  PASSWORD = 4,
}
interface ISignUp {
  initialStep?: number,
  initialPhone?: string,
  initialEmail?: string,
  disableBack?: boolean,
}

const getInitialStep = (phone: string, email: string) => {
  if (phone) {
    return STEPS.EMAIL;
  }
  if (email) {
    return STEPS.PASSWORD;
  }
  return STEPS.PHONE;
}

const SignUp: React.FC<ISignUp> = function({
  initialStep ,
  initialPhone = '',
  initialEmail = '',
  disableBack = false,
}) {
  const { t } = useTranslation();
  const [enabledNextStep, setEnabledNextStep] = useState<boolean>(false);
  const [phoneCode, setPhoneCode] = useState<{ value: string, label: string }>(defaultOption);
  const [phone, setPhone] = useState<string>(() => {
    if (!initialPhone) {
      return '';
    }
    const phoneCode = phoneCodesOptions.find(({ value }) => initialPhone.startsWith(value));
    if (!phoneCode) {
      return '';
    }
    setPhoneCode(phoneCode);
    return initialPhone.slice(phoneCode.value.length);
  });
  const [verifiedPhone, setVerifiedPhone] = useState<string>(`+${initialPhone}`);
  const [codeId, setCodeId] = useState<number>(0);
  const [emailCodeId, setEmailCodeId] = useState<number>(0);
  const [code, setCode] = useState<string>('');
  const [emailCode, setEmailCode] = useState<string>('');
  const [step, setStep] = useState<number>(initialStep || getInitialStep(initialPhone, initialEmail));
  const [email, setEmail] = useState<string>(initialEmail);
  const [verifiedEmail, setVerifiedEmail] = useState<string>(initialEmail);
  const [password, setPasswordState] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [time, startTimer,, stopTimer] = useTimer(1000, RETRY_TIME, DIRECTIONS.DEC);
  const [retryDisabled, setRetryDisabled] = useState(true);
  const [trigger] = useSetPasswordMutation();
  const history = useHistory();
  const dispatch = useDispatch();

  if (!time && retryDisabled) {
    setRetryDisabled(false);
    stopTimer();
  }

  const updateNextStepBtn = useCallback(() => {
    const phoneNumber = `${phoneCode.label}${phone}`;
    const enabledNextStepNew = (step === STEPS.PHONE && isPhoneNumber(phoneNumber)) ||
      (step === STEPS.PHONE_CODE && code.length === CODE_LENGTH) ||
      (step === STEPS.EMAIL && isEmail(email)) ||
      (step === STEPS.EMAIL_CODE && emailCode.length === CODE_LENGTH) ||
      (step === STEPS.PASSWORD && password === passwordRepeat && isPasswordValid(password));
    if (enabledNextStepNew !== enabledNextStep) {
      setEnabledNextStep(enabledNextStepNew);
    }
  },[step, phoneCode, phone, code, email, emailCode, password, passwordRepeat, enabledNextStep])
  updateNextStepBtn();

  const onChangePhone = useCallback((value: string) => {
    setPhone(value);
  }, [setPhone]);

  const onChangePhoneCode = useCallback((value) => {
    setPhoneCode(value);
  }, [setPhoneCode]);

  const onChangeCode = useCallback((value) => {
    setCode(value);
  }, [setCode]);

  const onChangeEmailCode = useCallback((value) => {
    setEmailCode(value);
  }, [setEmailCode]);

  const onChangeEmail = useCallback((value) => {
    setEmail(value);
  }, [setEmail]);

  const onChangePassword = useCallback((value) => {
    setPasswordState(value);
  }, [setPasswordState]);

  const onChangePasswordRepeat = useCallback((value) => {
    setPasswordRepeat(value);
  }, [setPasswordRepeat]);

  const sendCode = useCallback(async () => {
    setCodeId(await UserService.sendCode(`+${phoneCode.value}${phone}`));
    startTimer();
    setRetryDisabled(true);
  }, [setCodeId, phone, phoneCode, startTimer]);

  const retrySendingCode = useCallback(async () => {
    setCodeId(await UserService.sendCode(`+${phoneCode.value}${phone}`));
    startTimer();
    setRetryDisabled(true);
  }, [setCodeId, phoneCode, phone, startTimer, setRetryDisabled]);

  const verifyCode = useCallback(async () => {
    const token = await UserService.verifyCode(`+${phoneCode.value}${phone}`, codeId, code);
    dispatch(logIn(token));
  }, [phoneCode, phone, code, codeId, dispatch]);

  const sendCodeToEmail = useCallback(async () => {
    setEmailCodeId(await UserService.sendCodeToEmail(email));
    startTimer();
    setRetryDisabled(true);
  }, [setEmailCodeId, email, startTimer]);

  const retrySendingCodeToEmail = useCallback(async () => {
    setEmailCodeId(await UserService.sendCodeToEmail(email));
    startTimer();
    setRetryDisabled(true);
  }, [setEmailCodeId, email, startTimer, setRetryDisabled]);

  const verifyCodeToEmail = useCallback(async () => {
    await UserService.verifyCodeToEmail(email, emailCodeId, emailCode);
  }, [email, emailCodeId, emailCode]);

  const setPassword = useCallback(async () => {
    await trigger(password);
    history.push(HOME.path);
  }, [password, history, trigger]);

  const goBack = useCallback(() => {
    switch (step) {
      case STEPS.PHONE: {
        history.push(LOGIN.path);
        break;
      }
      case STEPS.PHONE_CODE:
      case STEPS.EMAIL: {
        setStep(STEPS.PHONE);
        break;
      }
      case STEPS.EMAIL_CODE: {
        setStep(STEPS.EMAIL);
        break;
      }
      case STEPS.PASSWORD: {
        if (email.length) {
          setStep(STEPS.EMAIL);
        } else {
          setStep(STEPS.PHONE)
        }
        break;
      }
    }
  }, [history, step, email]);

  const steps = [(
    <div className="signup__input-container-wrapper">
      <p className="signup__label">
        {t('viewp.signup.number')}
      </p>
      <div className="signup__input-container">
        <Select
          isSearchable={false}
          options={phoneCodesOptions}
          value={phoneCode}
          onChange={onChangePhoneCode}
        />
        <TextInput
          type="tel"
          value={phone}
          placeholder={t('viewp.signup.number-placeholder')}
          onChangePlain={onChangePhone}
          invalid={!isPhoneNumber(`${phoneCode.label}${phone}`) && !!phone}
        />
      </div>
      <p className="signup__note" dangerouslySetInnerHTML={{ __html: t('viewp.signup.note1') }} />
    </div>
  ),(
    <div className="signup__input-container-wrapper">
      <p className="signup__label">
        {t('viewp.signup.code')}
      </p>
      <div className="signup__number-container">
        <p className="number-container__phone">
          {phoneCode.label}{phone}
        </p>
        <InlineButton disabled={retryDisabled} onClick={retrySendingCode} >
          {
            retryDisabled
              ? t('viewp.signup.retry-in', { time })
              : t('viewp.signup.retry')
          }
        </InlineButton>
      </div>
      <TextInput
        type="tel"
        value={code}
        onChangePlain={onChangeCode}
      />
    </div>
  ),(
    <div className="signup__input-container-wrapper">
      <p className="signup__label">
        {t('viewp.signup.email')}
      </p>
      <div className="signup__input-container">
        <TextInput
          type="email"
          value={email}
          placeholder="email@gmail.com"
          onChangePlain={onChangeEmail}
        />
      </div>
      <p className="signup__note">{t('viewp.signup.note2')}</p>
    </div>
  ),(
    <div className="signup__input-container-wrapper">
      <p className="signup__label">
        {t('viewp.signup.code')}
      </p>
      <div className="signup__number-container">
        <p className="number-container__phone">
          {email}
        </p>
        <InlineButton disabled={retryDisabled} onClick={retrySendingCodeToEmail} >
          {
            retryDisabled
              ? t('viewp.signup.retry-in', { time })
              : t('viewp.signup.retry')
          }
        </InlineButton>
      </div>
      <TextInput
        type="tel"
        value={emailCode}
        onChangePlain={onChangeEmailCode}
      />
    </div>
  ),(
    <div className="signup__input-container-wrapper">
      <p className="signup__label">
        {t('viewp.signup.password-label')}
      </p>
      <div className="signup__input-container-column">
        <TextInput
          type="password"
          value={password}
          placeholder={t('viewp.signup.password-placeholder')}
          onChangePlain={onChangePassword}
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
        />
        <TextInput
          type="password"
          value={passwordRepeat}
          placeholder={t('viewp.signup.password-repeat-placeholder')}
          onChangePlain={onChangePasswordRepeat}
          invalid={!!passwordRepeat && password !== passwordRepeat}
        />
      </div>
      <p className="signup__note">{t('viewp.signup.password-rules')}</p>
    </div>
  )];
  console.log(step);

  const toPasswordStep = useCallback(() => {
    console.log('to password');
    setStep(STEPS.PASSWORD);
  }, []);

  const changeStep = useCallback(async () => {
    try {
      const entirePhone = `+${phoneCode.value}${phone}`;
      switch (step) {
        case STEPS.PHONE: {
          if (verifiedPhone === entirePhone) {
            setStep(STEPS.EMAIL);
            return;
          }
          dispatch(enableLoading());
          await sendCode();
          dispatch(disableLoading());
          break;
        }
        case STEPS.PHONE_CODE: {
          dispatch(enableLoading());
          await verifyCode();
          dispatch(disableLoading());
          setCode('');
          setVerifiedPhone(entirePhone);
          break;
        }
        case STEPS.EMAIL: {
          if (verifiedEmail === email) {
            setStep(STEPS.PASSWORD);
            return;
          }
          dispatch(enableLoading());
          await sendCodeToEmail();
          dispatch(disableLoading());
          break;
        }
        case STEPS.EMAIL_CODE: {
          dispatch(enableLoading());
          await verifyCodeToEmail();
          dispatch(disableLoading());
          setVerifiedEmail(email);
          setEmailCode('');
          break;
        }
        case STEPS.PASSWORD: {
          dispatch(enableLoading());
          await setPassword();
          dispatch(disableLoading());
          break;
        }
      }
      if (step + 1 >= steps.length) {
        history.push(LOGIN.path);
        return;
      }
      setEnabledNextStep(true);
      setStep(step + 1);
    } catch (err) {
      dispatch(disableLoading());
      if (err.isAxiosError) {
        toast.error(err.response.data.message);
      } else {
        toast.error(t('viewp.error.default'));
      }
    }
  }, [setStep, step, history, steps.length, sendCode, verifyCode,
    sendCodeToEmail, verifyCodeToEmail, setPassword, email, phone, t,
    verifiedEmail, verifiedPhone, phoneCode.value, dispatch]);

  return (
    <div className="signup__container">
      <div className="signup__header">
        {
          disableBack &&
          <figure
            onClick={goBack}
            className="signup__back"
          >
            <img src={leftArrow} alt=""/>
          </figure>
        }
        <figure className="signup__logo">
          <img src={logo} alt=""/>
        </figure>
      </div>
      <Stepper steps={steps} currentStep={step} />
      <div className="signup-bts-container">
        <Button
          disabled={!enabledNextStep}
          className="signup__button"
          onClick={changeStep}
        >
          {t('viewp.signup.button')}
        </Button>
        <InlineButton
          className={cs('signup__skip-btn', { 'signup__skip-btn--hidden': step !== STEPS.EMAIL })}
          onClick={toPasswordStep}>
          {t('viewp.signup.email-skip')}
        </InlineButton>
      </div>
    </div>
  )
}

export default SignUp;
