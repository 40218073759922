import { useCallback, useState } from 'react';

export const DIRECTIONS: {
  INC: 'inc',
  DEC: 'dec',
} = {
  INC: 'inc',
  DEC: 'dec',
}


export const useTimer = (step: number, startValue: number = 0, direction: 'inc' | 'dec' = DIRECTIONS.INC): [number, Function, Function, Function] => {
  const [time, setTime] = useState(startValue);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const timerJob = useCallback(() => {
    switch (direction) {
      case DIRECTIONS.DEC: {
        setTime((prevTime) => prevTime - 1);
        break;
      }
      case DIRECTIONS.INC:
      default: {
        setTime((prevTime) => prevTime + 1);
        break;
      }
    }
  }, [setTime, direction]);

  const pauseTimer = useCallback(() => {
    if (!timerId) {
      return;
    }
    clearInterval(timerId)
    setTimerId(null);
  }, [timerId, setTimerId]);

  const stopTimer = useCallback(() => {
    pauseTimer();
    setTime(startValue);
  }, [pauseTimer, setTime, startValue]);

  const startTimer = useCallback(() => {
    stopTimer();
    setTimerId(setInterval(timerJob, step));
  }, [setTimerId, stopTimer, timerJob, step])


  return [time, startTimer, pauseTimer, stopTimer]
}
