import BaseApiService from './BaseApiService';
import { getPhoneOrEmail } from '../utils/phone';

export enum UserStatuses {
  REGISTRATION_IN_PROGRESS = 'registration_in_progress',
  REGISTERED = 'created',
  PASSWORD_RESET_IN_PROGRESS = 'password_reset_in_progress',
}

export interface IUser {
  id: number;
  businessId: number | null;
  phone: string;
  email: string | null;
  createdAt: Date;
  updatedAt: Date;
  isPasswordSet: boolean;
  status: UserStatuses;
}

class UserService extends BaseApiService {
  constructor() {
    super('users');
  }

  async sendCode(phone: string): Promise<number> {
    const { data } = await this.service.post('/sign-up/send-code', { phone });
    return data.codeId;
  }

  async verifyCode(phone: string, codeId: number, code: string): Promise<string> {
    const { data } = await this.service.post('/sign-up/verify-code', { phone, codeId, code });
    return data.token;
  }

  async signIn(login: string, password: string): Promise<string> {
    const { email, phone } = getPhoneOrEmail(login)
    const { data } = await this.service.post('/sign-in', { email, phone, password });
    return data.token;
  }

  async sendCodeToEmail(email: string): Promise<number> {
    const { data } = await this.serviceWithAuth.post('/link-email/send-code', { email });
    return data.codeId;
  }

  async verifyCodeToEmail(email: string, codeId: number, code: string): Promise<void> {
    await this.serviceWithAuth.post('/link-email/verify-code', { email, codeId, code });
  }

  async setPassword(password: string): Promise<void> {
    await this.serviceWithAuth.post('/set-password', { password });
  }
}

export default new UserService();
