import axios, { AxiosInstance } from 'axios';
import TokenService from './TokenService';
import ConfigService from './ConfigService';

class BaseApiService {
  protected service: AxiosInstance;
  protected serviceWithAuth: AxiosInstance;
  constructor(urlPrefix: string) {
    const businessApiConfig = ConfigService.get('businessApi');

    const baseUrl = `${businessApiConfig.baseUrl}/v1/${urlPrefix}`
    this.service = axios.create({ baseURL: baseUrl });

    this.serviceWithAuth = axios.create();
    this.serviceWithAuth.interceptors.request.use((config) => {
      const token = TokenService.getToken();

      return {
        baseURL: baseUrl,
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
        }
      };
    })
  }
}

export default BaseApiService;
