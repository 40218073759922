import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useTranslation } from 'react-i18next';
import shortLogo from '../../assets/logo-short.svg';
import image from '../../assets/welcome.svg';
import { Button } from '../../components/common';
import './Welcome.css'
import { BUSINESS_CREATION } from '../../constants/routes';


const Welcome: React.FC = function() {
  const { t } = useTranslation();

  return (
    <div className="welcome__container">
      <div className="welcome__header">
        <figure className="welcome__logo">
          <img src={shortLogo} alt=""/>
        </figure>
        <figure className="welcome__image">
          <img src={image} alt=""/>
        </figure>
      </div>
      <div>
        <h1 className="welcome__title">{t('viewp.welcome.title')}</h1>
        <p className="welcome__text">{t('viewp.welcome.text')}</p>
        <ol className="welcome__list">
          <li className="welcome__list-item">{t('viewp.welcome.list-item.1')}</li>
          <li className="welcome__list-item">{t('viewp.welcome.list-item.2')}</li>
          <li className="welcome__list-item">{t('viewp.welcome.list-item.3')}</li>
          <li className="welcome__list-item">{t('viewp.welcome.list-item.4')}</li>
        </ol>
      </div>
      <Button
        className="welcome__button"
        to={BUSINESS_CREATION.path}
      >
        {t('viewp.welcome.button')}
      </Button>
    </div>
  )
}

export default Welcome;
