import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from 'react-spinners/PacmanLoader';
import cs from 'classnames';
import { RootState } from '../../store/store';
import './GlobalSpinner.css'
import { useIsLoaded } from '../../utils/hooks/isLoaded';


const GlobalSpinner: React.FC = function () {
  const isPageLoaded = useIsLoaded();
  const isLoading = useSelector((state: RootState) => state.appSettings.isLoading);
  const isSplashLoading = useSelector((state: RootState) => state.appSettings.isSplashLoading);

  return (
    <div
      className={cs('spinner__wrapper', {
        'spinner__wrapper--hidden': !isLoading && !isSplashLoading && isPageLoaded,
        'spinner__wrapper--splash': isSplashLoading || !isPageLoaded,
      })}
    >
      <Spinner color="#465282"/>
    </div>
  )
}

export default GlobalSpinner;
