export interface IBusinessApiConfig {
  baseUrl: string
}

export interface IViewpConfig {
  baseUrl: string
}

export interface IConfig {
  businessApi: IBusinessApiConfig;
  viewp: IViewpConfig;
}

const MANDATORY_ENVS = ['REACT_APP_BUSINESS_SERVICE_URL']

class ConfigService {
  private readonly config: IConfig;
  constructor() {
    const missingVars = MANDATORY_ENVS.filter(envVar => !process.env[envVar])
    if (missingVars.length) {
      throw new Error(`Env vars are missing: ${missingVars.join(', ')}`);
    }

    const businessApiConfig = {
      baseUrl: process.env.REACT_APP_BUSINESS_SERVICE_URL as string
    }

    const viewpConfig = {
      baseUrl: process.env.REACT_APP_VIEWP_SERVICE_URL as string
    }
    this.config = {
      businessApi: businessApiConfig,
      viewp: viewpConfig,
    }
  }

  get(key: 'businessApi'): IBusinessApiConfig;
  get(key: 'viewp'): IViewpConfig;
  get(key: keyof IConfig) {
    return this.config[key];
  }
}

export default new ConfigService();

