import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import get from 'lodash/get';
import storageService from '../services/StorageService';

export const authMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      const status = get(action, 'payload.status');
      if (status === 403) {
        storageService.remove(storageService.KEYS.TOKEN);
      }
    }

    return next(action)
  }
