import i18n, { i18n as Ii18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE_KEYS } from '../../constants/language';
import ru from './ru.json';
import en from './en.json';

const { EN, RU } = LANGUAGE_KEYS;

export const detectLanguage = (i18n?: Ii18n) => {
  if (i18n && i18n.language) {
    return i18n.language;
  }
  // TODO here can be logic detecting language
  return RU;
};

i18n.languages = [EN, RU];

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    [RU]: ru,
    [EN]: en,
  },
  lng: detectLanguage(),
  fallbackLng: RU,
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false,
  interpolation: {
      escapeValue: false
  }
}).then(() => i18n.changeLanguage(detectLanguage()));


export default i18n;
