import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/common';
import { HOME } from '../../constants/routes';
import './VerificationIsSent.css';


const VerificationIsSent: React.FC = function() {
  const { t } = useTranslation();
  return (
    <div className="verification-container">
      <h1 className="verification-container__title">{t('viewp.verification.title')}</h1>
      <p className="verification-container__text">{t('viewp.verification.text')}</p>
      <Button to={HOME.path} className="verification-container__button">{t('viewp.verification.continue')}</Button>
    </div>
  )
}

export default VerificationIsSent;
