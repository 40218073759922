import BaseApiService from './BaseApiService';
import { FileContent } from 'use-file-picker/dist/interfaces';
import axios from 'axios';

class UtilitiesService extends BaseApiService {
  constructor() {
    super('utilities');
  }

  async uploadImage(image: FileContent): Promise<string> {
    const fileType = image.content.split(':')[1].split(';')[0];
    const { data: { url, imageUrl } } = await this.serviceWithAuth.get<{ url: string, imageUrl: string }>('/upload-file-url', {
      params: { type: fileType }
    });
    const buffer = Buffer.from(image.content.replace(/^data:image\/\w+;base64,/, ""),'base64');
    await axios.put(url, buffer, {
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64'
      }
    });
    return imageUrl;
  }
}

export default new UtilitiesService();
