import { Route } from '../types/routes';

export const ONBOARDING: Route = {
    path: '/onboarding'
}

export const HOME: Route = {
    path: '/'
}

export const SIGN_UP: Route = {
    path: '/sign-up'
}

export const SIGN_IN: Route = {
    path: '/sign-in'
}

export const DETAILS: Route = {
    path: '/details'
}

export const LOGIN: Route = {
    path: '/login'
}

export const WELCOME: Route = {
    path: '/welcome'
}

export const FORGOT_PASSWORD: Route = {
    path: '/forgot-password'
}

export const BUSINESS_CREATION: Route = {
    path: '/business-creation'
}

export const VERIFICATION_IS_SENT: Route = {
    path: '/verification-is-sent'
}
