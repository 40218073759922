import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import TokenService from '../services/TokenService';

export type currentUserState = Readonly<{
  token: string | null
}>;

const initialState: currentUserState = {
  token: TokenService.getToken(),
}

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<string>) => {
      TokenService.setToken(action.payload);
      state.token = action.payload;
    },
    logOut: (state) => {
      state.token = null;
    }
  },
})

export const { logIn, logOut } = currentUserSlice.actions

export default currentUserSlice.reducer;
