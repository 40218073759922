import validator from 'validator'

export function isEmail (email: string): boolean {
  return validator.isEmail(email);
}

export function isPasswordValid (password: string): boolean {
  return validator.isStrongPassword(password, { minLength: 8, minUppercase: 1, minLowercase: 1, minNumbers: 1, minSymbols: 0 })
}

export function isPhoneNumber (phone: string): boolean {
  return validator.isMobilePhone(phone, 'any', { strictMode: true });
}