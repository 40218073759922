import React, { useCallback, useEffect, useState } from 'react';
import './RadioSelect.css';

export interface ItemComponentProps<ValueType>{
  item: ValueType;
  checked: boolean;
}

export interface RadioSelectProps<ValueType extends { id: any }> {
  values: Array<ValueType>;
  className?: string;
  onChange: (card: ValueType) => void;
  selectedValue?: number;
  itemContainerClassName?: string;
  ItemComponent: React.FC<ItemComponentProps<ValueType>>;
}

function RadioSelect<ValueType extends { id: any }>({
  values,
  className,
  itemContainerClassName,
  onChange,
  selectedValue,
  ItemComponent,
}: RadioSelectProps<ValueType>) {
  const selectedIndex = values.findIndex(({ id }) => selectedValue === id);
  const [inputName, setInputName] = useState<string>('');
  const onChecked = useCallback((index) => {
    onChange(values[index]);
  }, [onChange, values]);

  useEffect(() => {
    setInputName(`${Math.random()}`);
  }, [values]);

  return (
    <div className={className}>
      {
        values.map((item, index) =>
          <label key={item.id} className={itemContainerClassName} >
            <input className="radio-select-input" type="radio" onChange={() => onChecked(index)} checked={index === selectedIndex} name={inputName}/>
            <ItemComponent
              item={item}
              checked={index === selectedIndex}
            />
          </label>
        )
      }
    </div>
  )
}


export default RadioSelect;
