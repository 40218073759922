import React from 'react';
import Select, { Props } from 'react-select';
import './Select.css'

const CustomSelect: React.FC<Partial<Props>> = function ({ children , ...props}) {
  return (
    <Select
      className="select"
      classNamePrefix="select"
      {...props}
    >
      {children}
    </Select>
  )
}

export default CustomSelect;
