import React, { useCallback, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { enableLoading, disableLoading } from '../../store/appSettingsSlice';
import { useForgotPasswordMutation, useForgotPasswordVerifyMutation } from '../../store/userApiSlice';
import leftArrow from '../../assets/left-long-arrow.svg';
import logo from '../../assets/logo.svg';
import { Button, InlineButton, Stepper } from '../../components/common';
import TextInput from '../../components/common/TextInput';
import { LOGIN, SIGN_IN } from '../../constants/routes';
import { isEmail } from '../../utils/validation';
import './ForgotPassword.css'
import { DIRECTIONS, useTimer } from '../../utils/hooks/timer';
import { toast } from 'react-toastify';
import { logIn } from '../../store/currentUserSlice';


export enum STEPS {
  LOGIN = 0,
  CODE = 1,
  PASSWORD = 2,
}
const RETRY_TIME = 60;
const CODE_LENGTH = 6;
const TIMER_STEP = 1000; // in ms

const ForgotPassword: React.FC = function() {
  const { t } = useTranslation();
  const [enabledNextStep, setEnabledNextStep] = useState<boolean>(false);
  const [step, setStep] = useState<number>(STEPS.LOGIN);
  const [codeId, setCodeId] = useState<number>(0);
  const [login, setLogin] = useState<string>('');
  const [loginValid, setLoginValidity] = useState<boolean>(false);
  const [forgotPassword] = useForgotPasswordMutation();
  const [forgotPasswordVerify] = useForgotPasswordVerifyMutation();
  const [time, startTimer,, stopTimer] = useTimer(TIMER_STEP, RETRY_TIME, DIRECTIONS.DEC);
  const [code, setCode] = useState<string>('');
  const [retryDisabled, setRetryDisabled] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  if (!time && retryDisabled) {
    setRetryDisabled(false);
    stopTimer();
  }

  const updateNextStepBtn = useCallback(() => {
    const enabledNextStepNew = (step === STEPS.LOGIN && loginValid) ||
      (step === STEPS.CODE && code.length === CODE_LENGTH);
    if (enabledNextStepNew !== enabledNextStep) {
      setEnabledNextStep(enabledNextStepNew);
    }
  },[step, loginValid, code, enabledNextStep])
  updateNextStepBtn();

  const onChangeLogin = useCallback((value: string) => {
    const isValid = isValidPhoneNumber(value) || isValidPhoneNumber(`+${value}`) || isEmail(value);
    setLogin(value);
    setLoginValidity(isValid);
  }, [setLogin]);

  const goBack = useCallback(() => {
    history.push(SIGN_IN.path);
  }, [history]);

  const onChangeCode = useCallback((value) => {
    setCode(value);
  }, [setCode]);

  const verifyCode = useCallback(async () => {
    dispatch(enableLoading());
    const response = await forgotPasswordVerify({ codeId, login, code });
    if ('error' in response) {
      dispatch(disableLoading());
      throw response.error;
    }
    dispatch(logIn(response.data.token));
    dispatch(disableLoading());
    setCode('');
  }, [dispatch, codeId, code, login, forgotPasswordVerify]);

  const sendCode = useCallback(async () => {
    dispatch(enableLoading());
    const response = await forgotPassword(login);
    if ('error' in response) {
      dispatch(disableLoading());
      throw response.error;
    }
    setCodeId(response.data.codeId);
    startTimer();
    setCode('');
    setRetryDisabled(true);
    dispatch(disableLoading());
  }, [dispatch, login, forgotPassword, startTimer]);

  const steps = [(
    <div className="forgot_password__main-input-container">
      <p className="forgot_password__label">
        {t('viewp.forgot-password.login-text')}
      </p>
      <div className="forgot_password__input-container">
        <TextInput
          value={login}
          invalid={!loginValid && !!login}
          placeholder={t('viewp.forgot-password.login')}
          onChangePlain={onChangeLogin}
        />
      </div>
    </div>
  ),(
    <div className="forgot_password__main-input-container">
      <p className="forgot_password__label">
        {t('viewp.signup.code')}
      </p>
      <div className="forgot_password__login-container">
        <p className="forgot_password__login">
          {login}
        </p>
        <InlineButton disabled={retryDisabled} onClick={sendCode} >
          {
            retryDisabled
              ? t('viewp.signup.retry-in', { time })
              : t('viewp.signup.retry')
          }
        </InlineButton>
      </div>
      <TextInput
        type="tel"
        value={code}
        onChangePlain={onChangeCode}
      />
    </div>
  )];

  const changeStep = useCallback(async () => {
    try {
      switch (step) {
        case STEPS.LOGIN: {
          await sendCode();
          break;
        }
        case STEPS.CODE: {
          await verifyCode();
          break;
        }
      }
      if (step + 1 >= steps.length) {
        history.push(LOGIN.path);
        return;
      }
      setEnabledNextStep(true);
      setStep(step + 1);
    } catch (err) {
      dispatch(disableLoading());
      if (err.isAxiosError) {
        toast.error(err.response.data.message);
      } else {
        toast.error(t('viewp.error.default'));
      }
    }
  }, [setStep, step, sendCode, verifyCode, history, steps.length, t, dispatch]);

  return (
    <div className="forgot_password__container">
      <div className="forgot_password__header">
        <figure
          onClick={goBack}
          className="forgot_password__back"
        >
          <img src={leftArrow} alt=""/>
        </figure>
        <figure className="forgot_password__logo">
          <img src={logo} alt=""/>
        </figure>
      </div>
      <Stepper steps={steps} currentStep={step} />
      <Button
        disabled={!enabledNextStep}
        className="forgot_password__next-btn"
        onClick={changeStep}
      >
        {t('viewp.forgot-password.button')}
      </Button>
    </div>
  )
}

export default ForgotPassword;
